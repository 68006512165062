@import "./assets/styles/forms/forms.css";
@import "./assets/styles/common/typography.css";
@import "./assets/styles/common/header.css";
@import "./assets/styles/common/tables.css";
@import "./assets/styles/common/filter-bar.css";

:root {
  /* Colors  */
  --sembraBlue: #0000c2;
  --sembraGold: #caa819;
  --sembraCashGreen: #036704;
  --mainBgColor: whitesmoke;
  --boxShadow: #504f4f;
  --errBackground: rgb(159, 0, 0);

  /* Spacing  */
  --xs-space: 0.25rem;
  --sm-space: 0.5rem;
  --md-space: 0.75rem;
  --lg-space: 1rem;
  --xl-space: 2rem;

  /* Font Size  */
  --xs-font-size: 0.65rem;
  --sm-font-size: 0.75rem;
  --md-font-size: 1rem;
  --lg-font-size: 1.25rem;
  --xl-font-size: 1.5rem;
}

/* Here will live app layout css */
.App {
  background: var(--mainBgColor);
  height: 100vh;
}

header {
  width: 100%;
  background: white;
  display: flex;
  justify-content: space-between;
  padding: var(--lg-space);
  box-shadow: 0 1px 5px var(--boxShadow);
}

header img {
  width: 200px;
}

main {
  max-width: 1200px;
  margin: 0 auto;
  background: var(--mainBgColor);
  margin-top: var(--sm-space);
  padding: var(--md-space);
}

.main-section {
  box-shadow: 0 0 5px var(--boxShadow);
  margin-bottom: var(--md-space);
}

.section-content {
  padding: var(--md-space);
  text-align: center;
}
