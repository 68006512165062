.main-section h1 {
  font-size: clamp(24px, 3vw, 3vh);
  background: var(--sembraCashGreen);
  color: white;
  padding: var(--xs-space);
  margin-bottom: 0;
}

.main-section.error h1 {
  background: var(--errBackground);
  /* background: red; */
  color: white;
}

.section-content p:last-of-type {
  margin-bottom: 0;
}
