.date-filter,
.global-filter {
  text-align: left;
  margin-bottom: var(--md-space);
}

.react-datepicker__view-calendar-icon input {
  padding: 2px 10px 5px 25px !important;
}

@media screen and (min-width: 520px) {
  .filter-bar {
    display: flex;
    justify-content: space-around;
    align-items: end;
  }
}
