.table-container {
  max-height: 400px; /* Set the maximum height of the container */
  overflow-y: auto; /* Enable vertical scrollbar when content overflows */
  overflow-x: auto;
}

tr.clickable-row {
  cursor: pointer;
}

tr.clickable-row:hover {
  background: rgba(202, 168, 25, 0.3);
}
