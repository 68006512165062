.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-form,
.send-money-form {
  padding: var(--md-space);
  max-width: 480px;
  margin: auto;
}

.auth-form select {
  margin-bottom: var(--md-space);
}

.auth-form.hide {
  display: none;
  visibility: hidden;
}

.auth-form.show {
  visibility: visible;
}

/* .send-money-form .show {
  display: block;
  visibility: visible;
}

.send-money-form .hide {
  display: block;
  visibility: hidden;
} */

.icon-input {
  display: flex;
  align-items: center;
}

.icon-input svg {
  position: absolute;
  margin: 1px 5px;
}

.icon-input input[type="text"] {
  /* padding-left: var(--md-space); */
  /* padding-left: 20px; */
  padding-left: var(--xl-space);
}
