.user-menu {
  display: flex;
  align-items: center;
}

.user-menu svg {
  font-size: 2rem;
  margin-right: var(--md-space);
  cursor: pointer;
}
